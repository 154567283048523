<template>
  <panel title="User datas">
    <!-- use component -->
    <user-form-model
      ref="UserFormModel"
      @reload="$refs.dataTableUser.queryItmes()"
    />
    <!-- end use component -->
    <data-table-local
      ref="dataTableUser"
      :columns="_columns"
      @clickNew="$refs.UserFormModel.show()"
      @clickEdit="$refs.UserFormModel.show($event)"
      @clickView="clickView"
      :ableActionDelete="true"
      url="/api/userData"
      :pre="false"
    />
  </panel>
</template>

<script>
export default {
  components: {
    UserFormModel: () => import("./Components/Index/UserFormModal.vue"),
  },
  computed: {
    _columns() {
      return [
        {
          label: "Email",
          field: "email",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Username",
          field: "username",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Role",
          field: "role",
          thClass: "text-center",
          type: "string",
        },
        // {
        //   label: "url repository",
        //   field: "url_repository",
        //   thClass: "text-center",
        //   type: "string",
        // },
      ]
    },
  },
  methods: {
    clickView(data) {
      this.$router.push({
        name: "users-view",
        params: {
          user_id: data.id,
        },
      })
    },
  },
}
</script>
